<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/intellectual.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/intellectual.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/offer">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO GOSPODARCZE</h4>
		</template>

		<template #title>
			<h2>Własność intelektualna i prace B+R</h2>
		</template>

		<template #text>
			<p>Specjalizujemy się w zakresie ochrony patentowej, znaków towarowych, czy praw autorskich. Obsługujemy zagadnienia związane z nowoczesnymi technologiami oraz ochroną praw twórców. Wspomagamy przedsiębiorstwa prowadzące działalność badawczo-rozwojową, które cieszą się coraz większą popularnością. Wspieramy Naszych Klientów w ochronie ich produktów przed nielegalnym importem, naruszeniami praw do znaków towarowych, czy ich podrabianiem.</p>
		</template>

		<template #range>
			<h3></h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">IP Box</li>
				<li class="offer__list-item">Prawo autorskie</li>
				<li class="offer__list-item">Znaki towarowe i domeny internetowe</li>
				<li class="offer__list-item">Wzory przemysłowe, wynalazki i wzory użytkowe</li>
				<li class="offer__list-item">Zwalczanie nieuczciwej konkurencji</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	components: {
		Offer
	},
	name: 'intellectual property',
	mixins: [handleWith],
}
</script>